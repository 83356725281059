<template>
  <v-container fluid full class="mt-10 mb-7">
    <!--Call action-->

    <v-container fluid class="mt-2 hidden-sm-and-down">
        <v-row wrap>
          <v-col cols="12">
            <v-img
              :src="'img/lines.png'"
              contain
              height="50px"
              max-width="100%"
            ></v-img>
          </v-col>
        </v-row>
    </v-container>

    <v-row class="mt-10 mb-2">
        <v-col cols="12" md="5" class="mt-5">
            <h1>Download App Now</h1>
            <p class="text-justify mt-4">
              Gain access to fully serviced healthcare facilities across 
              multiple locations to meet with your patients. We offer a monthly and pay-per-use tariff plan. 
              Avoid unnecessary setup and administrative costs
            </p>
            <v-row wrap>
             <v-col col="12" md="6">
              <a href="https://play.google.com/store/apps/details?id=com.ecrts.qik_pharma_mobile&pcampaignid=web_share" target="_blank">
                <v-img
                src="https://res.cloudinary.com/dxw06ktju/image/upload/v1679492611/app-store-3c761ce03142459404e70bcd42726598_oxcprw.svg"
                contain
                width="100%"
                ></v-img>
              </a>
              </v-col>
              
              
              <v-col col="12" md="6">
                <a href="#" target="_blank">
                  <v-img
                  src="https://res.cloudinary.com/dxw06ktju/image/upload/v1679492611/google-play-6d94b3e437453b37a29bb410c259305d_fnojmh.svg"
                  contain
                  width="100%"
                  ></v-img>
                </a>
              </v-col>
              
            </v-row>
        </v-col>
        <v-col cols="12" md="7">
          <v-img
            src="https://res.cloudinary.com/dxw06ktju/image/upload/v1679491546/qikpharma_mobile_nobg_s1akcs.png"
            contain
            height="426px"
            max-width="100%"
          ></v-img>
        </v-col>
    </v-row>

    <v-container fluid class="mt-2 hidden-sm-and-down">
        <v-row wrap>
          <v-col cols="12">
            <v-img
              :src="'img/lines.png'"
              contain
              height="20px"
              max-width="100%"
            ></v-img>
          </v-col>
        </v-row>
    </v-container>

    <v-container class="searchContainer">
      <v-row wrap>
        <v-col col="12" justify="center" align="center">
          <span class="titleStyleColor">Quick</span>
          <span class="titleStyle"> Find</span>
          <h5>
            Find Trusted Pharmacies around your location
          </h5>
        </v-col>
      </v-row>

      <v-row wrap class="filterSession mb-2 mt-5" >
          <v-col cols="12" md="9">
            <v-row wrap class="mb-4" style="margin-top:80px;">
              <v-col cols="12" md="5" offset-md="3">
                <v-autocomplete
                  name="retailerList"
                  color="#000000"
                  v-model="retailerUserId"
                  label="Search for a trusted pharmacy"
                  :items="retailerList"
                  item-text="companyName"
                  item-value="User.userId"
                >
                </v-autocomplete>
              </v-col>
              

              <v-col align="left" cols="3" offset="3" md="3" class="mt-2">
                <v-btn align="center" color="#000000"
                style="color:#ffffff;" @click.prevent="findRetailerShop">
                  Find
                  <v-icon class="mr-2">
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="3">
            <v-img
            :src="'img/locations.png'"
            cover
            height="250px"
            max-width="100%"
          ></v-img>
          </v-col>

      </v-row>

       
    </v-container>

    <v-container fluid class="mt-2 hidden-sm-and-down">
      <v-row wrap>
         <v-col cols="12">
          <v-img
            :src="'img/lines.png'"
            contain
            height="250px"
            max-width="100%"
          ></v-img>
         </v-col>
      </v-row>
    </v-container>

    <v-container fluid >
      <v-row wrap>
        <v-col cols="12" align="center" justify="center">
          <h3>
            Our Pharma Store Service are here for you
          </h3>
          <p>
            Verified qikPharma services help ensure that your purchases are
            protected always.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" v-for="(item, i) in servicesList"
              :key="i">
          <v-card
            class="mx-auto my-4"
            max-width="374"
          >
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
            <router-link :to="item.link" style="text-decoration: none;">
            <v-img
              height="250"
              opacity="1"
              :src="item.serviceImage"
            >
             <div class="overlay">
            <v-card-title class="serviceStyle text-center">{{item.serviceDiscription}}</v-card-title>
             </div>
            </v-img>
            </router-link>

            <v-card-title style="font-size:16px !important;">{{item.serviceName}}</v-card-title>

            <v-card-text>
              <div>{{item.serviceType}}</div>
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>

    </v-container>
  </v-container>
</template>

<script>

export default {
  name: "SearchMD",

  created() {
    this.getRetailers();
    this.getRegions();
    this.isAuth = this.$auth.isAuthenticated();
  },

  data: () => ({
    overlay: false,
    model: null,
    retailerList: [],
    retailerUserId:'',
    selectLocation: { state: "Select Location", abbr: "GH" },
    regions: [],
    selectedItem: null,
    servicesList: [
      {
        id: 1,
        serviceName: "TRADE ASSURANCE",
        serviceType: "Order Protection",
        serviceDiscription:
          "Order assurance is a free protection service offered by qikPharma",
        serviceImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632403294/service1_rebi1k.png",
        link: "/trade-assurance"
      },
      {
        id: 2,
        serviceName: "PAYMENT",
        serviceType: "Payment Solution",
        serviceDiscription:
          "Fast payment and secured gateway plaforms for easy ordering and security",
        serviceImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632403294/service2_omofp1.png",
        link: "/payment-information"
      },
      {
        id: 3,
        serviceName: "INSPECTION SOLUTION",
        serviceType: "Inspection",
        serviceDiscription: "Monitoring and inspecting services on your orders",
        serviceImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632403294/service3_lupo2z.png",
        link: "/"
      },
      {
        id: 4,
        serviceName: "OCEAN & SHIPPING",
        serviceType: "Logistics",
        serviceDiscription: "Fast reliable shipping by ocean or air",
        serviceImage:
          "https://res.cloudinary.com/ecrtechdev/image/upload/v1632403294/service4_jpneu0.png",
        link: "/"
      },
    ],
    isAuth: null,
  }),
  methods: {
    getRetailers() {
      this.$http
        .get(`${process.env.VUE_APP_URL}retailers`)
        .then((response) => {
          this.retailerList = response.data.data;
        });
    },

    getRegions() {
      this.$http
        .get(`${process.env.VUE_APP_URL}regions`)
        .then((response) => {
          this.regions = response.data.data;
        });
    },

    findRetailerShop() {
      this.$router.push({
              path: "/store/0/" + this.retailerUserId,
      });
    }
  },
};
</script>

<style scoped>
.filterSession {
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  max-height: auto;
  margin-top: 15px;
}



.buttonStyle {
  width: auto;
  height: auto;
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 10px;
}

.titleStyle {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
}

.titleStyleColor {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  background: -webkit-linear-gradient(#72bd61, #111212);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100% !important;
}

.v-parallax__content2 {
  position: none !important;
  padding: 0px !important;
}

.textStyle {
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 70px;
  line-height: 95px;
  text-align: center;
}

.textP {
  margin-left: 100px;
  font-size: 20px;
}

.nostyle {
  text-decoration: none;
  color: #000000;
}

.serviceStyle {
  color:#ffffff;
  padding-top:100px;
  font-size: 12px;
  line-height:1rem;
  
}
</style>
