<template>
  <v-container fluid>
        <v-row >
          <v-carousel
            cycle
            class="hidden-sm-and-down"
            :show-arrows="false"
            hide-delimiters
            interval="15000"
            height="500" 
            >
            <v-carousel-item
              v-for="(item, i) in slides"
              :key="i"
              :src="item.src"
              transition="fade"
              reverse-transition="fade"
            >
              <div class="overlay">
                <v-row class="fill-height" style="padding-top:200px;">
                  <v-col cols="10" offset="1" style="color:#ffffff;">
                    <v-layout wrap class="text-h3" style="color:#ffffff;">
                      <b>{{ item.title }}</b>
                    </v-layout>
                    <p class="mt-5" style="font-size: 18px;">
                      {{ item.text }}
                    </p>
                    <p>
                      <v-btn class="mt-2" size="12" small :to="item.link">
                        {{item.btnText}}
                      </v-btn>
                    </p>
                  </v-col>
                </v-row>
              </div>
            </v-carousel-item>
          </v-carousel> 
          <v-carousel
            class="hidden-md-and-up"
            cycle
            :show-arrows="false"
            hide-delimiters
            height="200px"
            interval="10000"
          >
            <v-carousel-item
              v-for="(item, i) in slides"
              :key="i"
              :src="item.src"
              transition="fade"
              reverse-transition="fade"
            >
              <div class="overlay">
                <v-row class="fill-height pt-5">
                  <v-col cols="8" offset="1" style="color:#ffffff;">
                    <v-layout wrap class="text-h6" style="color:#ffffff;">
                      {{ item.title }}
                    </v-layout>
                    <p>
                      {{ item.text }}
                    </p> 
                  </v-col>
                </v-row>
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-row>
        <v-row>
          <Categories></Categories>
        </v-row>
  </v-container>
  </template>
  
  <script>
  import Categories from "../others/Categories.vue";
  
  export default {
    name: "SlideHolder",
   components: {
      Categories,
    },
    data: () => ({
      selectedItem: null,
      categoriesList: null,
      slides: [
        {
          src: "https://res.cloudinary.com/dxw06ktju/image/upload/v1716981551/DMA_9717_qzfaiz.jpg",
          title: "Transforming Healthcare Accessibility Together",
          text: "qikPharma isn't just a platform, it's a revolution.",
          btnText: "Learn more",
          link: "/about-us",
        },
        {
          src: "https://res.cloudinary.com/dxw06ktju/image/upload/v1697799380/Slider-01_khxbol.png",
          title: "Trusted Drugs Around You",
          text: "Get drugs from trusted pharmacies around you.",
          btnText: "Shop Now",
          link: "/stores",
        },
        {
          src: "https://res.cloudinary.com/dxw06ktju/image/upload/v1697799387/Slider-2_crafkj.png",
          title: "Refill Prescriptions",
          text: "Check Prescription status, order a refill now.",
          btnText: "Refill Now",
          link: "/prescription/dashboard/en",
        },
      ],
      categoryItems: null,
    }),
    created() {
      this.getProductCat();
    },
    methods: {
      getProductCat() {
        this.$http
          .get(`${process.env.VUE_APP_URL}product-categories`)
          .then((response) => {
            this.categoryItems = response.data.data;
          });
        
      },
    },
  };
  </script>
  
  <style scoped>
  .overlay {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
  }
  </style>
  