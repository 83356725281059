<template>
  <v-row>
    <v-col cols="12" style="padding:0px !important;">
      <!-- <HeaderSearch class="hidden-sm-and-down"></HeaderSearch> -->
      <SlideHolder></SlideHolder>
      <Products></Products>
      <SearchMD></SearchMD>
    </v-col>
  </v-row>
</template>

<script>
// import HeaderSearch from "./others/HeaderSearch.vue";
import SlideHolder from "./others/SlideHolder.vue";
import Products from "./products/Products";
import SearchMD from "./others/SearchMD.vue";


export default {
  name: "HomePage",

  components: {
    // HeaderSearch,
    SlideHolder,
    Products,
    SearchMD,
  },

  data: () => ({}),
};
</script>

<style scoped></style>
