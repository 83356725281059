<template>
  <v-row class="hidden-sm-and-down" style="background-color:#ffffff; margin-left:2px;">
              <v-col cols="3">
                <v-layout class="box1">
                  <v-col col="12" c>
                    <h5 class="mt-7" align="center">Manufacturer</h5>
                    <v-layout>
                      <v-col cols="12" align="center">
                        <v-btn color="#000000"
                               style="color:#ffffff;" 
                               class="ma-2" 
                               size="12" small to="/system-manufacturers">
                          <span>Connect now</span>
                        </v-btn>
                      </v-col>
                    </v-layout>
                  </v-col>
                </v-layout>
              </v-col>

              <v-col cols="3">
                <v-layout class="box1">
                  <v-col col="12">
                    <h5 class="mt-7" align="center">Distributor</h5>
                    <v-layout>
                      <v-col cols="12" align="center">
                        <v-btn class="ma-2" size="12" small to="/distributors/store" v-if="distributorMenu">
                          Shop Now
                        </v-btn>
                        <v-btn color="#000000"
                               style="color:#ffffff;" 
                              class="ma-2" size="12" small to="/system-distributors" v-if="!distributorMenu">
                          <span>Know more</span>
                        </v-btn>
                      </v-col>
                    </v-layout>
                  </v-col>
                </v-layout>
              </v-col>
            
              <v-col cols="3">
                <v-layout class="box1">
                  <v-col col="12" >
                    <h5 class="mt-7" align="center">Retailer</h5>
                    <v-layout>
                      <v-col cols="12" align="center">
                        <v-btn color="#000000"
                               style="color:#ffffff;" 
                              class="ma-2" size="12" small to="/retailers/store" v-if="retailerMenu">
                              <span>Shop Now</span>
                        </v-btn>
                        <v-btn color="#000000"
                               style="color:#ffffff;" 
                              class="ma-2" size="12" small to="/system-retailers" v-if="!retailerMenu">
                              <span>Know More</span>
                        </v-btn>
                      </v-col>
                    </v-layout>
                  </v-col>
                </v-layout>
              </v-col>

              <v-col cols="3">
                <v-layout class="box1">
                  <v-col col="12">
                    <h5 class="mt-7" align="center">My Prescriptions</h5>
                    <v-layout>
                      <v-col cols="12" align="center">
                        <v-btn color="#000000"
                               style="color:#ffffff;" class="ma-2" size="12" small to="/login" v-if="!isAuth">
                        <span>Order</span>
                        </v-btn>
                        <v-btn color="#000000"
                               style="color:#ffffff;"  class="ma-2" size="12" small to="/upload/user-prescription" v-if="isAuth">
                          <span>Order</span>
                        </v-btn>
                      </v-col>
                    </v-layout>
                  </v-col>
                </v-layout>
              </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Categories",

  created() {
    this.setAuthenticatedUser();
  },

  data: () => ({
    selectedItem: null,
    colors: ["indigo", "warning", "pink darken-2"],
    manufactureMenu: null,
    distributorMenu: null,
    retailerMenu: null,
    userId:"",
    isAuth:false,
  }),


  methods: {
    setAuthenticatedUser() {
      this.userId = localStorage.getItem("userId");
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + this.userId)
        .then((response) => {
          this.$auth.setAuthenticatedUser(response);
          this.isAuth = true;
          if (response.data.data.Role.name == "manufacturer") {
            this.manufactureMenu = true;
          }

          if (response.data.data.Role.name == "distributor") {
            this.distributorMenu = true;
            this.storeOwner = true;
          }

          if (response.data.data.Role.name == "retailer") {
            this.retailerMenu = true;
          }
        });
    },
  },
};
</script>

<style scoped>
span {
  text-transform: none;
}

.box1 {
  background-color: #81C24B;
  border-radius: 10px;
  color: #ffffff;
}

.barcodeTitle {
  color: #000000;
  font-size: 16px;
  text-align: center;
}

.barcodeText {
  color: #c2c2c2;
  font-size: 12px;
  margin-top: 15px;
  text-align: center;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}
</style>
